// @import "node_modules/spectre.css/src/spectre";
@import "./Variables";
@import "./Spectre.scss";

.container {
    padding: 0 3rem;
}

h1#title {
    text-align: center;
}

.logo {
    height: 3em;
}

.content {
    // background-color: $border-color;
    margin: 4rem 0;
    padding: 2rem;
}

.tab .tab-item a {
    font-weight: bold;
    font-size: 1.2em;
}